<template>
  <v-card>
    <v-img
      src="https://vblimages.blob.core.windows.net/images/aa744b42-ba45-476b-9ca8-05202e513d77.svg"
      :height="imgHeight"
      contain
    ></v-img>
    <v-tabs v-model="window" grow>
      <v-tab :key="0">Beach</v-tab>
      <v-tab :key="1">Grass</v-tab>
    </v-tabs>
    <v-container>
      <v-window v-model="window">
        <v-window-item :key="0">
          <v-row>
            <v-col cols="6" class="text-right pa-0">Rating:</v-col>
            <v-col cols="6" class="pa-0 pl-3">{{ player.movrObj.total | fixed2 }}</v-col>
            <v-col cols="6" class="text-right pa-0">Confidence:</v-col>
            <v-col cols="6" class="pa-0 pl-3">{{ (player.movrObj.confidence * 100) | fixed1OrInt }}%</v-col>
          </v-row>
        </v-window-item>
        <v-window-item :key="1">
          <v-row>
            <v-col cols="6" class="text-right pa-0">Rating:</v-col>
            <v-col cols="6" class="pa-0 pl-3">{{ player.movrGObj.total | fixed2 }}</v-col>
            <v-col cols="6" class="text-right pa-0">Confidence:</v-col>
            <v-col cols="6" class="pa-0 pl-3">{{ (player.movrGObj.confidence * 100) | fixed1OrInt }}%</v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: ['player', 'imgHeight', 'defaultSurface'],
  data () {
    return {
      window: this.defaultSurface === 'grass' ? 1 : 0
    }
  },
  computed: {
  }
}
</script>
